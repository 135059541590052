import { COMPONENT_TYPE } from './common'

export const PLACEHOLDER_RULE: Record<string, any> = {
  [COMPONENT_TYPE.STRING]: 'input-value',
  [COMPONENT_TYPE.DECIMAL]: 'input-value',
  [COMPONENT_TYPE.INTEGER]: 'input-value',
  [COMPONENT_TYPE.DATETIME]: 'input-value',
  [COMPONENT_TYPE.DROPDOWN]: 'select-status',
  [COMPONENT_TYPE.BETWEEN]: ['input-value', 'input-value'],
  [COMPONENT_TYPE.CHECKED_DROPDOWN]: 'input-value',
  [COMPONENT_TYPE.TAGS]: 'input-value',
  [COMPONENT_TYPE.MULTIPLE_DATE]: 'input-value',
  [COMPONENT_TYPE.AUTOCOMPLETE]: 'input-value',
}
export const DEFAULT_COMPONENT_PARAMS: Record<string, any> = {
  [COMPONENT_TYPE.STRING]: { parser: undefined, formatter: undefined },
  [COMPONENT_TYPE.DROPDOWN]: {},
  [COMPONENT_TYPE.DECIMAL]: { maxlength: 16 },
  [COMPONENT_TYPE.INTEGER]: { maxlength: 16 },
  [COMPONENT_TYPE.DATETIME]: { type: 'date', 'is-date-disabled': '' },
  [COMPONENT_TYPE.BETWEEN]: {
    type: '',
    startPlaceholder: '',
    endPlaceholder: '',
  },
  [COMPONENT_TYPE.CHECKED_DROPDOWN]: { clearable: true },
  [COMPONENT_TYPE.TAGS]: { inputProps: { maxlength: 16 } },
  [COMPONENT_TYPE.MULTIPLE_DATE]: {},
  [COMPONENT_TYPE.AUTOCOMPLETE]: { url: '', params: {} },
  [COMPONENT_TYPE.DROPDOWN_INPUT]: { maxlength: 16 },
}

export const DEFAULT_LAYER: Record<string, string> = {
  ads: 'ads',
  keywords: 'keyword',
  discovery_location: 'discovery_location',
}

export const DEFAULT_STATUS: Record<string, string> = {
  ongoing: 'strategy-detail.specific-list.status.ongoing',
  paused: 'strategy-detail.specific-list.status.paused',
}

export const DEFAULT_AUDIENCE: Record<string, string> = {
  auto: 'strategy-detail.specific-list.audience-value.auto',
  manual: 'strategy-detail.specific-list.audience-value.manual',
}

export const BIDDING_METHOD_SHOPEE_PRODUCT_ADS: Record<string, string> = {
  auto: 'strategy-detail.specific-list.bidding-method-value.auto',
  manual: 'strategy-detail.specific-list.bidding-method-value.manual',
  roi_two: 'strategy-detail.specific-list.bidding-method-value.roi_two',
}

export const DEFAULT_BIDDING_METHOD: Record<string, string> = {
  auto: 'strategy-detail.specific-list.bidding-method-value.auto',
  manual: 'strategy-detail.specific-list.bidding-method-value.manual',
}

export const DEFAULT_PLACEMENT: Record<string, string> = {
  all: 'strategy-detail.specific-list.placement-value.all',
  search_product:
    'strategy-detail.specific-list.placement-value.search_product',
  targeting: 'strategy-detail.specific-list.placement-value.targeting',
}

export const SUB_ACTION_NUMBER = [
  'increase_specific_amount',
  'decrease_specific_amount',
  'increase_by_percentage',
  'decrease_by_percentage',
]

export const DISABLED_KEYWORDS = ['shopee_discovery_ads']

export const DEFAULT_KEYWORD: Record<string, string> = {
  broad: 'strategy-detail.specific-list.status.broad',
  exact: 'strategy-detail.specific-list.status.exact',
}

export const DEFAULT_DISCOVERY_LOCATION: Record<string, string> = {
  you_may_also_like: 'strategy-detail.specific-list.fields.you_may_also_like',
  daily_discover: 'strategy-detail.specific-list.fields.daily_discover',
}

export const REALLOCATE_OPTONS: Record<string, string> = {
  pause_campaign: 'options.pause-campaigns',
  set_daily_minimum: 'options.set-daily-budget-minimum',
}
export enum PERFORMER_ORDER {
  HIGHEST = 'highest',
  LOWEST = 'lowest',
}
export const PERFORMER_OPTIONS: Record<string, string> = {
  [PERFORMER_ORDER.HIGHEST]: 'options.highest',
  [PERFORMER_ORDER.LOWEST]: 'options.lowest',
}

export enum REALLOCATE_ENUM {
  reallocate = 'reallocate_daily_budget',
  default_lazada = 'reallocate_pause_campaigns',
  default_shopee = 'reallocate_set_minium_budget',
}
export enum TargetApply {
  ADS = 'ads',
  KEYWORDS = 'keywords',
  DISCOVERY_LOCATION = 'discovery_location',
}
export enum TargetType {
  ALL = 'all',
  SPECIFIC = 'specific',
}
export enum EXECUTED_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  NO_ISSUES = 'no_issues',
  ERROR = 'error',
  NO_ACTION = 'no_action',
}
export enum EXECUTED_LIMIT_BUDGET_STATUS {
  LIMIT_REACHED = 'under_plf_minimum_budget',
  ALREADY_LIMIT = 'over_plf_maximum_budget',
  MAX_DAILY_BUDGET = 'over_campaign_maximum_budget',
  OVER_BUDGET_LIMIT_REACH = 'store_budget_limit_reach',
  SET_TO_CURRENT_SPEND = 'set_to_the_current_spend',
  SET_TO_MINIMUM_LOW_SPEND = 'set_to_minimum_due_to_low_spend',
  CHANGE_STATUS_DAILY_BUDGET = 'change_status_fail_due_to_campaign_limit_reach',
  CHANGE_STATUS_FAILED_BY_LIMIT_REACH = 'change_status_fail_due_to_store_limit_reach',
}
export enum STRATEGY_STATUS_ENUM {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
export const STRATEGY_STATUS: Record<string, string> = {
  [STRATEGY_STATUS_ENUM.ACTIVE]: 'assign-strategy.status.active',
  [STRATEGY_STATUS_ENUM.INACTIVE]: 'assign-strategy.status.inactive',
}
export const STRATEGY_EXECUTION_STATUS: Record<string, string> = {
  no_issues: 'assign-strategy.status.no_issues',
  success: 'strategy-detail.executed-list.status.success',
  error: 'assign-strategy.status.error',
  failed: 'strategy-detail.executed-list.status.failed',
  pending: 'assign-strategy.status.pending',
}
export enum STRATEGY_RULE_SCHEDULE {
  STORE_LOCAL_TIME = 'store_local_time',
  CAMPAIGN_TYPE = 'campaign_type',
  BIDDING_STRATEGY = 'bidding_strategy',
  PRODUCT_PLACEMENT = 'product_placement',
  CAMPAIGN_PRODUCT_PLACEMENT = 'campaign_product_placement',
}
export const DEFAULT_CAMPAIGN_TYPE: Record<string, string> = {
  automated: 'strategy-detail.rule-info.condition-options.automated',
  mega_sales_accelerator:
    'strategy-detail.rule-info.condition-options.mega_sales_accelerator',
  manual: 'strategy-detail.rule-info.condition-options.manual',
  new_product_launcher:
    'strategy-detail.rule-info.condition-options.new_product_launcher',
  promote_specific_products:
    'strategy-detail.rule-info.condition-options.promote_specific_products',
}
export const STRATEGY_KEY_CACHE = 'nextStep'
export const SPECIFIC_ROW_KEY = 'hashId'
export enum DEFAULT_TYPE {
  TEXT = 'text',
}
export const EXECUTION_LOG_STATUS: Record<string, string> = {
  error: 'filters-status.error',
  failed: 'filters-status.error',
  no_action: 'filters-status.none',
  pending: 'filters-status.pending',
  success: 'filters-status.success',
}
export const PERCENTAGE_METRIC_KEY: string[] = [
  'click_through_rate',
  'conversion_rate',
  'spend_per_daily_budget',
]
export const REALLOCATE_METRIC: Record<string, string> = {
  spend: PERFORMER_ORDER.LOWEST,
  units_sold: PERFORMER_ORDER.HIGHEST,
  direct_units_sold: PERFORMER_ORDER.HIGHEST,
  roas: PERFORMER_ORDER.HIGHEST,
  direct_roas: PERFORMER_ORDER.HIGHEST,
  paid_gmv: PERFORMER_ORDER.HIGHEST,
  direct_paid_gmv: PERFORMER_ORDER.HIGHEST,
  conversion_rate: PERFORMER_ORDER.HIGHEST,
  click_through_rate: PERFORMER_ORDER.HIGHEST,
  cpm: PERFORMER_ORDER.LOWEST,
  cpc: PERFORMER_ORDER.LOWEST,
  cpo: PERFORMER_ORDER.LOWEST,
  clicks: PERFORMER_ORDER.HIGHEST,
  impressions: PERFORMER_ORDER.HIGHEST,
  orders: PERFORMER_ORDER.HIGHEST,
  add_to_cart_units: PERFORMER_ORDER.HIGHEST,
}
export const REALLOCATE_OPERETOR: Record<string, string> = {
  '>=': '<',
  '<=': '>',
  '=': '!=',
  '!=': '=',
  '>': '<=',
  '<': '>=',
  in: 'not_in',
  not_in: 'in',
  [PERFORMER_ORDER.HIGHEST]: '>',
  [PERFORMER_ORDER.LOWEST]: '<',
}
export enum DEFAULT_REALLOCATE_CONDITIONS {
  ROAS = 'roas',
}
export enum DEFAULT_CONDITIONS {
  STATUS = 'status',
  CAMPAIGN_STATUS = 'campaign_status',
}
export const RESERVE_STATUS: Record<string, string> = {
  ongoing: 'paused',
  paused: 'ongoing',
}
export enum RULE_ACTIONS_ENUMS {
  CHANGE_STATUS = 'change_status',
}
export const DEFAULT_HISTORICAL: Record<string, string> = {
  specific_value: 'string',
  channel_average: 'dropdown',
}
export enum HISTORICAL_ENUMS {
  SPECIFIC = 'specific_value',
  AVERAGE = 'channel_average',
}
