import 'pinia'

import type { CommonNS, StrategyNS } from '@/typings/common'
import type {
  CampaignNS,
  DataTableNS,
  DateNS,
  MetricNS,
  TradeDeskNS,
} from '@/typings'
import type { USER_PARTNER_TYPE, USER_STATUS } from '@/constants/common'

declare module 'pinia' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface PiniaCustomProperties<Id, S> {
    setState: <T extends keyof S>(state: T, value: S[T]) => void
  }
}

// module[common]
export namespace Common {
  export const name = 'common'
  export interface State {
    locale: CommonNS.Language
    expandSidebar: boolean
    renderAllowed: boolean
    customNTheme: any
  }
}

// module[controller]
export namespace Controller {
  export const name = 'controller'
  export interface State {
    controllers: AbortController[]
  }
}

// module[campaign]
export namespace Campaign {
  export const name = 'campaign'
  export interface State {
    topFilters: {
      platform: MetricNS.Platform
      channel: MetricNS.Channel
      store: CommonNS.TmallStore | null
      dates: {
        mode: MetricNS.DYNAMIC_DATE
        customerTimestamp: number[]
      }
    }
    config: {
      authorizedStores: CommonNS.TmallStore[]
      metricConfigs: MetricNS.MetricConfig[]
    }
    tmallStores: any[]
    userStores: CommonNS.DataList
    campaignList: CampaignNS.CampaignListData
    informationTargets: CampaignNS.InformationTargets
  }
}

// module[campaign]
export namespace TradeDesk {
  export const name = 'trade-desk'
  export interface State {
    topFilters: {
      subPage: TradeDeskNS.SUB_PAGE
      onlyShowAlert: boolean
      storeOrderRefreshed: boolean
      date: DateNS.DateState
      compareDate: DateNS.CompareDateState
    }
    config: {
      authorizedStores: CommonNS.TmallStore[]
    }
  }
}

// module[user]
export namespace User {
  export const name = 'user'
  export interface State {
    idToken: string
    accountInfo: {
      email: string
      name: string
      phone: string
      source: string
      userId: string
      status: USER_STATUS
      partnerType: USER_PARTNER_TYPE
    }
  }
}

export namespace AssignedStrategy {
  export const name = 'assigned-strategy'
  export interface State {
    data: {
      rows: Array<any>
      total: number
    }
    stores: Array<StrategyNS.StoreDataProps>
    rules: StrategyNS.RuleDataProps
    strategyData: StrategyNS.StrategyDataProps
    details: StrategyNS.StrategyDetailProps
    strategyExecutedAction: StrategyNS.StrategyExecutedActionProps
    options: StrategyNS.DataListProps
    specifics: StrategyNS.StrategySpecificsProps
    validate: Array<StrategyNS.StrategyValidProps>
    keywords: Array<any>
    hasErrorDisplayPage: boolean
    templateList: Record<string, StrategyNS.TemplateDataList[]>
    executionLog: StrategyNS.StrategyExecutionLog
  }
}

export namespace DataTable {
  export const name = 'data-table'
  export interface State {
    params: DataTableNS.DataTableParams
    routeParams: DataTableNS.BaseFilterPanel
    cacheFilters: {
      options: DataTableNS.BaseFilterPanel
      customs: DataTableNS.BaseFilterPanel
    }
  }
}
